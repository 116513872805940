function initGTM() {
  if (window.isGTMLoaded) {
    return false
  }

  window.isGTMLoaded = true

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.defer = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=GTM-KG2Z25X`

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", `GTM-KG2Z25X`)
  }

  document.head.appendChild(script)
}

function runDuringIdle() {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(initGTM, {
      timeout: 2000,
    })
  } else {
    setTimeout(initGTM, 4500)
  }
}

function loadGTM(event) {
  runDuringIdle()
  event.currentTarget.removeEventListener(event.type, loadGTM)
}

exports.onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      runDuringIdle()
    }
  }

  document.addEventListener("scroll", loadGTM)
  document.addEventListener("mousemove", loadGTM)
  document.addEventListener("touchstart", loadGTM)
}
